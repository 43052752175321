<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="page-info-title">{{ $route.meta.title }}</div>
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">
                        {{ farmer.idNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">银行卡号</span>
                      <span class="descriptions-item-content">
                        {{ farmer.bankNo }}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">合同信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">签署方式</span>
                      <span class="descriptions-item-content">线下签约</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">

                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">合同照片</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <image-shows :imgs="viewImages(metadata.files)"></image-shows>
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { viewImages } from "@/utils/common";
import  { gridConnectionStatusMapper, statusMapper } from "@/utils/helper";
import {getSignatureById} from "@/api/apis";
import ImageShows from "@/components/ImageShows.vue";

export default {
  name: "SettlementInfo",
  components: {ImageShows, PageHeader},
  data(){
    return {
      id: '',
      metadata: {},
      farmer: {},
    }
  },
  mounted() {
    this.id = this.thisId
    this.getInfo()
  },
  methods: {
    gridConnectionStatusMapper,
    viewImages,
    statusMapper,
    /**
     * 获取数据
     */
    getInfo(){
      getSignatureById(this.$route.params.id).then(res => {
        this.metadata = res
        this.farmer = this.metadata.farmer
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>